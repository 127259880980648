
export function getISO8601ShortDateFromDate(datestring) {
    let date = new Date(datestring)
    const delimiter = '-';
    return [date.getFullYear(), new String(date.getMonth() + 1).padStart(2, '0'), new String(date.getDate()).padStart(2, '0')].join(delimiter);
}

export function getISO8601dateFromDate(datestring) {
    if (!datestring) { return '' }
    let date = new Date(datestring)
    const year = [date.getFullYear(), new String(date.getMonth() + 1).padStart(2, '0'), new String(date.getDate()).padStart(2, '0')];
    const time = [new String(date.getHours()).padStart(2, '0'), new String(date.getMinutes()).padStart(2, '0')]
    return year.join('-') + " " + time.join(':')
}