
<template>
  <div class="boxed" :class="{'card':!showLoader && showActivity}">
      <v-progress-circular
          class="mt-10 align-self-center"
          v-if="showLoader"
          indeterminate
          :size="70"
          color="primary"
          :width="5"
      ></v-progress-circular>

    <v-scroll-x-reverse-transition>
      <div v-if="!showLoader && showActivity" style="flex: 1 1 auto; overflow-y: auto; min-height: 0px; background-color: rgb(215 215 215);" :class="scrollbarTheme">
          <global-log />
      </div>
    </v-scroll-x-reverse-transition>

    <v-scroll-x-reverse-transition>
      <div v-if="!showLoader && showTabs" style="flex: 1 1 auto; overflow-y: auto; min-height: 0px; overflow-x: hidden;" :class="scrollbarTheme">
          <v-card
            tile
            elevation="4"
            class="fill-height"
          >
            <v-card-text class="pa-0 flex-row fill-height">
              <v-row class="flex-row fill-height align-content-start bgfix" no-gutters>
                <v-col cols="12" class="grow">
                        <v-overlay
                          v-if="!loaded"
                          class="fill-height"
                        >
                          <v-progress-circular
                              class="mt-10"
                              indeterminate
                              color="primary"
                          ></v-progress-circular>
                        </v-overlay>
                      <v-row class="d-flex flex-column">
                        <top-bar :data="selection" :save="save" :cancel="cancel"></top-bar>
                        <v-tabs class="fill-height bgfix" color="#254b65 accent-4">
                          <v-tab class="bgfix">Details</v-tab>
                          <v-tab class="bgfix">Amounts</v-tab>
                          <v-tab class="bgfix">Attachments</v-tab>
                          <v-tab class="bgfix">Log</v-tab>
                          <v-tab-item height="100%" :key="2" class="bgfix">
                            <details-tab :claim-data="selection" :crew-data="crewSelection" :save="save" />
                          </v-tab-item>
                          <v-tab-item :key="3" class="bgfix">
                              <amount-tab :claim-data="selection" :crew-data="crewSelection" :save="save"/>
                          </v-tab-item>
                          <v-tab-item :key="4" class="bgfix">
                              <attachments :data="selection" :save="save"/>
                          </v-tab-item>
                          <v-tab-item :key="5" class="bgfix">
                              <log-tab :data="selection" :save="save"/>
                          </v-tab-item>

                        </v-tabs>

                      </v-row>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      </div>
    </v-scroll-x-reverse-transition>
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}     
    </v-snackbar>
  </div>
</template>
<script>
import DetailsTab from '../components/DetailsTab'
import AmountTab from '../components/AmountsTab'
import LogTab from '../components/LogTab'
import GlobalLog from '../components/GlobalLog'
import Attachments from './AttachmentsTab.vue'
import TopBar from './TopBar.vue'

export default {
  name: 'Tabs',
  data: () => ({
    loaded: false,
    showTabs: false,
    showActivity: true,
    timerReference: null,
    snackbar: false,
    text: ''
  }),
  components: {
    DetailsTab, AmountTab, LogTab, Attachments, GlobalLog, TopBar
  },
  watch: {
    'selection': function(newVal) {
      this.claim = newVal
      this.flickTabs()
     
    },
    'crewSelection': function(newVal) {
      this.crew = newVal
    }
  },
  computed: {
    showLoader() {
      return this.$store.state.loading
    },
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    selection() {
      return this.$store.state.openClaim
    },
    crewSelection(){
      return (this.$store.state.openClaim) ? this.$store.state.crew.find(f => f.ClaimId === this.$store.state.openClaim.id) : null
    },

    list() {
        if (!this.$store || !this.$store.state.audits || !this.$store.state.claims) {
          return null
        }
        const loglist = []
        for(const entry of this.$store.state.audits) {
          const found = loglist.find(f => f.CaseId == entry.CaseId)
          if(!found)
          {
            const claim = this.$store.state.claims.find(f => f.id === entry.CaseId)
            if(claim){
              const merged = { ...entry, 'claim': claim}
              loglist.push(merged)
            }
          }
        }
        return loglist
      },
  },
  methods: {
    flickTabs() {
      this.showTabs = (this.$store.state.openClaim != null) ? true: false
      this.showActivity = !this.showTabs
    },
    cancel() {
      // this.showTabs = false
      //this.$store.dispatch('showLoader').then(() =>
        this.$store.dispatch('unloadClaim').then(() => {
          //this.$router.push({ params: { id: null }, query: this.$route.query});
          //return this.$store.dispatch('hideLoader').then(()=> this.showActivity = true)
        })
      //)
    },
    async save(data) {
      let dataToSave = this.selection
      let crewDataToSave = this.crewSelection
      if(data) {
        // Update Deductible
        if(data.type === 'PI' && (data.damage==='Crew' || data.damage==='ECR')) {
          // Persondata, do nothing (exept the standard swaping of category) to claim object
        } else {
          //  Swapped away from persondata, clear out sensitive data
          crewDataToSave = this.clearPersonData(crewDataToSave.ClaimId)
        }
        dataToSave.InsuranceType = data.type
        dataToSave.DamageType = data.damage
        dataToSave.DamageTypeSubCategory = data.subs
        dataToSave.DeductibleAmount = this.getDeductbleAmount(data.type, data.damage, data.subs, dataToSave)
      }
      this.loaded = false
      await this.$store.dispatch('saveClaim', { claim: dataToSave}).catch((error) => {
          this.loaded = true
          this.$store.dispatch('showError', 'Error while saving claim data, please save any changes and reload this page \n', error)
      })
      await this.$store.dispatch('saveCrew', { crew: crewDataToSave}).catch((error) => {
        this.loaded = true
        this.$store.dispatch('showError', 'Error while saving crew data, please save any changes and reload this page \n', error)
      })
      this.loaded = true
    },
    clearPersonData(id)  {
      const result = {
            ClaimId: id,
            Rank: null,
            Name: null,
            Age: null,
            InjuryIllnessType: null,
            BodyArea: null,
            Diagnosis: null,
            MedicalRootCause: null,
            WorkRelated: null,
            SignedOff: null,
            ECRCoverage: null,
            CompensationAmount: null,
            CompensationType: null,
            Description: null,
      }
      return result
    },
    getDeductbleAmount(type, subtype, subsubtype, dataToSave) {
      let result = null
      if(type==='HM'){
          result = dataToSave['HMDeductibleAmount']
      } else if(type === 'PI') {
          if(subtype==='Crew' || subtype==='ECR') {
              if(subsubtype==='Other')
                result = dataToSave['PIDeductibleOther']
              else
                result = dataToSave['PIDeductibleCrew']
          } else if(subtype==='Cargo'){
              result = dataToSave['PIDeductibleCargo']
          } else {
              result = dataToSave['PIDeductibleOther']
          }
      }
      return result
    },
    open(claim) {
      this.$store.dispatch('openClaim', {claim: claim})
      if(claim.id != this.$route.params.id) {
        this.$router.push({ params: { id: claim.id }, query: this.$route.query})
      }
    },
    showInfo() {
      let lapsedTime = (Date.now() - this.$store.state.openClaimTime)/60000
      if(lapsedTime>5 && this.isAppIdle) {        
        this.text=`Claim data is older than 5 minutes (And you have been idle > 10 minutes), refreshing data`
        this.snackbar = true      
        this.open(this.selection)
      }
    }
  },
  mounted: function(){
    this.flickTabs()
      if(!this.timerReference) {
        this.timerReference = setInterval(this.showInfo, 60000);      
      }
    this.loaded = true
  },
}
</script>

<style>
.bgfix {
  background-color: rgb(215 215 215);
}
.boxed {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.v-tabs-items, .v-window__container, .v-window-item
{
  height: 100%;
  background-color:  rgb(215 215 215);
}
.v-slide-group__wrapper, .v-tabs-bar, .v-tabs-items
{
  background-color:  rgb(215 215 215) !important;
}
/* Remove uggly white box at start of tabs on overflowing */
.v-slide-group__prev {
  display: none !important;
}

</style>