<template>
  <div style="display: flex; flex-direction: column; height: 100%;">
    <v-slide-y-transition>
    <div v-if="!$store.state.loading" style="flex: none; margin-bottom: 10px;">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" lg="4" :xl="getCols('Vessel')">
              <v-combobox
                v-model="vessel"
                clearable
                outlined
                dense
                hide-details="auto"
                persistent-placeholder
                :items="vessels"
                :filter="onFilterVessels"
                label="Vessel"
                @change="registerFilter('vessel', vessel)">

              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" lg="4" :xl="getCols('CaseReference')">
              <v-autocomplete
                v-model="reference"
                outlined
                dense
                hide-details="auto"
                persistent-placeholder
                clearable
                item-text="CaseReference"
                item-value="CaseReference"
                :items="caseRefList"
                label="Case reference"
                @change="registerFilter('reference', reference)">
                <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="text-left" v-html="data.item.CaseReference"></v-list-item-title>
                    </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="4" :xl="getCols('Category')">
              <v-autocomplete
                v-model="category"
                dense
                hide-details="auto"
                persistent-placeholder
                outlined
                clearable
                item-text="InsuranceType"
                item-value="InsuranceType"
                :items="categoryList"
                label="Insurance type"
                @change="registerFilter('category', category)">
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="text-left" v-html="data.item.InsuranceType"></v-list-item-title>
                    </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
<!--             <v-col cols="12" sm="6" lg="4" :xl="getCols('Status')">
              <v-select
                outlined
                dense
                hide-details="auto"
                persistent-placeholder
                clearable
                :items="statusList"
                v-model="statusFilterValue"
                item-text="Value"
                item-value="Value"
                label="Status"
                @change="registerFilter('status', statusFilterValue)">
              >
                <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="text-left" v-html="data.item.Value"></v-list-item-title>
                    </v-list-item-content>
                </template>
              </v-select>
            </v-col> -->
<!--             <v-col cols="12" sm="6" lg="4" :xl="getCols('Type')">
              <v-autocomplete
                v-model="type"
                dense
                hide-details="auto"
                persistent-placeholder
                outlined
                clearable
                item-text="ClaimType"
                item-value="ClaimType"
                :items="typeList"
                label="Damage type"
                @change="registerFilter('type', type)">
                >
                <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="text-left" v-html="data.item.ClaimType"></v-list-item-title>
                    </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col> -->
            <v-col cols="12" sm="6" lg="4" :xl="getCols('Range')">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateRangeText"
                      label="Interval for incident"
                      append-icon="mdi-calendar"
                      persistent-placeholder
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="clearRange()"
                      @click:append="menu = !menu"
                    >
                    </v-text-field>
                </template>
                <v-card no-gutters>
                  <v-row class="ma-0">
                    <v-col cols="4">
                      <v-row class="d-flex flex-column pa-2">
                        <v-btn color="primary" class="mb-2" @click="setOneYearOld()">One year old</v-btn>
                        <v-btn color="primary" @click="setOneMonthOld()">One month old</v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="8">
                      <v-date-picker
                          v-model="dates"
                          range />
                    </v-col>
                  </v-row>
                  </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" lg="4" :xl="getCols('Name')">
            <v-combobox
                v-model="name"
                dense
                hide-details="auto"
                persistent-placeholder
                outlined
                clearable
                :items="crewList"
                label="Crew Name"
                >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" lg="4" :xl="getCols('Name')">
              <v-combobox
                  v-model="InjuryIllnessType"
                  dense
                  hide-details="auto"
                  persistent-placeholder
                  outlined
                  clearable
                  :items="getInjuryIllnessTypes"
                  label="Injury Illness type"
                  >
              </v-combobox>
            </v-col>

            <v-col cols="12" lg="12" :xl="getCols('Status')" class="d-flex justify-end">
                <status-widget v-model="statusfilter" :allbutton="true"/>
            </v-col>
            <v-col cols="12" lg="6">
              <v-checkbox dense hide-details="auto" v-model="onlyMine" label="Only my claims"></v-checkbox>
            </v-col>
            <v-col cols="10" lg="4" xl="4" class="d-flex justify-end">
              <v-btn color="primary" @click="clearFilters()">Clear filters</v-btn>
            </v-col>
            <v-col cols="2" lg="2">
              <v-card height="36px">
                <v-card-text class="pa-2 d-flex flex-column ">
                  {{total}}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    </v-slide-y-transition>
    <div class="scroller" :class="scrollbarTheme">

      <v-data-table
        :loading="$store.state.loading"
        v-model="selected"
        :headers="headers"
        :items="list"
        item-key="id"
        multi-sort
        hide-default-footer
        disable-pagination
        class="row-pointer"
        :item-class="row_classes"
        single-select
        dense
        @pagination="fetchTotal"
      >
        <template v-slot:item="{ item }">
            <tr @click="rowClick(item)" :key="item.name" :class="{'hi-lite': shouldHilite(item), 'pointer':true}">
              <td class="text-left" style="width: 150px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">{{item.Vessel}}</div>
                  </template>
                  <span>{{short(item)}}</span>
                </v-tooltip>
              </td>
              <td class="text-left">{{ item.CaseReference }} </td>
              <td class="text-left">{{ item.IncidentDate }} </td>
              <td class="text-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-on="on" v-bind="attrs" class="mr-1">{{toggleIconBasedOnCaseType(item)}}</v-icon>
                  </template>
                  <p>{{getToolTip(item)}}</p>
                </v-tooltip>
              </td>
              <td class="truncate text-left">
                {{summary(item)}}
              </td>
            </tr>
          </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import he from 'he';
// If you want to use in one of your components.
import StatusWidget from './StatusWidget'

  export default {
    name: 'FilterAndClaimsList',
    components: {StatusWidget},
    data: () => ({
        statusFilterValue: '',
        vessel: null,
        reference: null,
        category: null,
        type: null,
        name: null,
        statusfilter: 'Open',
        InjuryIllnessType: null,
        flashClaim: {},
        dates: [],
        menu: false,
        loaded: false,
        selected: [],
        hideClosed: true,
        onlyMine: true,
        total: 0,
    }),
    watch: {
      '$store.state.claims': function() {
        this.scrollIntoView()
      },
      '$store.state.crew': function() {
        this.scrollIntoView()
      },
      openClaim: function(claim) {
        if(claim && claim.id) {
          this.selected = [claim]
        } else {
          this.selected = []
        }
        this.scrollIntoView()
      },
      dates: function (newDate) {
        if(newDate.length==2){
          this.menu = false
          this.registerFilter('range', this.getDatesArrForFilter)
        }
      },
      statusfilter: function() {
        this.$nextTick(() => {
          this.scrollIntoView()
        })
      },
      onlyMine: function() {
        this.$nextTick(() => {
          this.scrollIntoView()
        })
      },
    },
    computed: {
      crew: () => this.$store ? this.$store.state.crew : [],
      claims: () =>  this.$store ? this.$store.state.claims : [],
      openClaim() {
        return this.$store.state.openClaim
      },
      scrollbarTheme() {
        return this.$vuetify.theme.dark ? 'dark' : 'light';
      },
      headers() {
        if(!this.$store.state.openClaim) {
          // Full width
          return [
              { text: 'Vessel', value: 'Vessel', filter: this.nameFilter, align: 'start', width: '160px', groupable: false},
              { text: 'Reference', value: 'CaseReference', filter: this.refFilter, width: '130px', groupable: false},
              { text: 'Ext ref', value: 'ExternalReference', width: '110px', filterable: false, sortable: false, align: ' d-none', groupable: false},
              { text: 'Incident Date', value: 'IncidentDate', width: '130px', filter: this.rangeFilter, groupable: false},
              { text: 'Category', value: 'InsuranceType', width: '180px', filter: this.categoryFilter, sortable: false, align: ' d-none' , groupable: false},
              { text: 'Type', value: 'ClaimType', width: '180px', filter: this.typeFilter, sortable: false, align: ' d-none' , groupable: false},
              { text: '', value: '', width: '20px', sortable: false, filterable: false, groupable: false},
              { text: 'Subject', value: 'Subject', width: '100%', filterable: false, sortable: false, groupable: false},
              { text: 'PIClub', value: 'PIClub', width: '120px', filterable: false, sortable: false, align: ' d-none' , groupable: false},
              { text: 'HMclub', value: 'HMClub', width: '120px', filterable: false, sortable: false, align: ' d-none' , groupable: false},
              { text: 'Status', value: 'Status', filter: this.statusFilter, sortable: false, align: ' d-none' , groupable: false},
            ]
        }
        // Minimized
        return [
            { text: 'Vessel', value: 'Vessel', filter: this.nameFilter, align: 'start', width: '160px', groupable: false},
            { text: 'Reference', value: 'CaseReference', filter: this.refFilter, width: '130px', groupable: false},
            { text: 'Ext ref', value: 'ExternalReference', width: '110px', sortable: false, align: ' d-none', groupable: false},
            { text: 'Incident Date', value: 'IncidentDate', width: '130px', filter: this.rangeFilter, groupable: false},
            { text: 'Type', value: 'ClaimType', width: '180px', filter: this.typeFilter, sortable: false, align: ' d-none' , groupable: false},
            { text: 'Category', value: 'InsuranceType', width: '180px', filter: this.categoryFilter, sortable: false, align: ' d-none' , groupable: false},
            { text: '', value: '', width: '20px', filterable: false, sortable: false, groupable: false},
            { text: 'Subject', value: 'Subject', width: '100%', filterable: false, sortable: false, groupable: false},
            { text: 'PIClub', value: 'PIClub', width: '120px', sortable: false, align: ' d-none' , groupable: false},
            { text: 'HMclub', value: 'HMClub', width: '120px', sortable: false, align: ' d-none' , groupable: false},
            { text: 'Status', value: 'Status', filter: this.statusFilter, sortable: false, align: ' d-none' , groupable: false},
          ]
      },
      getDatesArrForFilter() {
        return this.dates.length == 2 ? `from_${this.dates[0]}_to_${this.dates[1]}` : null
      },
      dateRangeText:{
            get () {
                return this.dates.join('~')
            },
            set () {
                this.dates = []
            }
      },
      list() {
        if(!this.$store.state.claims || !this.$store.state.handler) return []
        let pass1 = (this.statusfilter && this.statusfilter!='all') ? this.$store.state.claims.filter((c) => c.Status && c.Status === this.statusfilter) : this.$store.state.claims
        let result = (this.onlyMine) ? pass1.filter(f => this.$store.state.handler === f.ClaimsHandler) : pass1
        if(this.name) {
          let crewClaims = this.$store.state.crew.filter( f => f.Name && f.Name.startsWith(this.name)).map(m => m.ClaimId)
          result = result.filter(f => crewClaims.includes(f.id))
        }
        if(this.InjuryIllnessType){
          let crewClaims = this.$store.state.crew.filter(f => f.InjuryIllnessType === this.InjuryIllnessType).map(m => m.ClaimId)
          result = result.filter(f => crewClaims.includes(f.id))
        }
        if(this.openClaim!=null)  {
          const allreadyPresent = result.find(f => f.id === this.openClaim.id)
          if(!allreadyPresent) {
            result.push(this.$store.state.claims.find(f=> f.id === this.openClaim.id))
          }
        }
        return result
      },
      crewList() {
        if(!this.$store.state.claims || !this.$store.state.handler) return []
        let pass1 = (this.hideClosed) ? this.$store.state.claims.filter((c) => c.Status && (typeof c.Status == 'string') && c.Status.toLowerCase() != 'closed') : this.$store.state.claims
        let pass2 = (this.onlyMine) ? pass1.filter(f => f && f.ClaimsHandler && this.$store.state.handler && this.$store.state.handler.startsWith(f.ClaimsHandler)) : pass1
        let pass3 = pass2.map( m => m.id)
        let pass4 = this.$store.state.crew
                      .filter( f => pass3.includes(f.ClaimId) && f.Name)
                      .map( m => m.Name)
        let pass5 = pass4.sort((a,b)=> (typeof a == 'string') && a.localeCompare(b))
        return pass5
      },
      getInjuryIllnessTypes() {
        return this.$store.state.crew
            .filter((f) => f.InjuryIllnessType)
            .map((m) => m.InjuryIllnessType.trim())
            .sort((a,b)=> a.localeCompare(b))
      },

      statusList() {
        if(!this.$store.state.claims) return
        const noNulls = this.list.filter( f => f.Status )
        const sortable = noNulls.map(({Status, ...rest}) => ({...rest, Value: Status}))
        sortable.sort((a,b) => a.Value.charAt(0) > b.Value.charAt(0) ? 1 : (
          (a.Value.charAt(0) < b.Value.charAt(0)) ? -1 : 0)
        )
        return sortable
      },
      caseRefList() {
/*         return this.list
          .filter((f) => f.CaseReference)
          .map((m) => m.CaseReference.trim())
          .sort((a,b) => a.localeCompare(b)) */
        return this.sortAplhabetically(this.removeNulls(this.list, 'CaseReference'), 'CaseReference')
      },
      categoryList() {
        return this.sortAplhabetically(this.removeNulls(this.$store.state.claims, 'InsuranceType'), 'InsuranceType')
      },
      typeList() {
        return this.sortAplhabetically(this.removeNulls(this.list, 'ClaimType'), 'ClaimType')
      },
      crewNameList() {
        return this.sortAplhabetically(this.removeNulls(this.crewList, 'Name'), 'Name')
      },
      vessels() {
        if(!this.list) return []
        const sortable = this.list.map(item => (item.Vessel))
        sortable.sort((a,b) => a > b ? 1 : (
          (a < b) ? -1 : 0)
        )
        return sortable
      }
    },
    mounted: function(){
      if(!this.list) {
        return ""
      }
      if(this.$route.query.vessel){
        this.vessel = he.decode(this.$route.query.vessel)
      }
      if(this.$route.query.reference){
        this.reference = he.decode(this.$route.query.reference)
      }
      if(this.$route.query.status){
        this.statusFilterValue = he.decode(this.$route.query.status)
      }
      if(this.$route.query.category){
        this.category = he.decode(this.$route.query.category)
      }
      if(this.$route.query.type){
        this.type = he.decode(this.$route.query.type)
      }
      if(this.$route.query.user){
        this.$store.dispatch('setHandler',  {user: he.decode(this.$route.query.user)})
      }
      if(this.$route.query.range){
        const raw = he.decode(this.$route.query.range)
        const rawArr = raw.split("_")
        this.dates.push(rawArr[1])
        this.dates.push(rawArr[3])
      }

      if(this.openClaim != null) {
        this.selected = [this.openClaim]
        this.$nextTick(() => {
          this.scrollIntoView()
        })
      }
/*       this.storeWatcher = this.$store.watch((state) => state.openClaim, (newValue) => {
        if(!newValue){
          this.$router.push({ params: { id: null }, query: this.$route.query});

        }
        this.$nextTick(() => {
          console.log('Should be high lighting row')
            const el = this.$el.getElementsByClassName("hi-lite")[0]
            if(el) el.scrollIntoView({ behavior: 'smooth' })
          }
        )}) */
      this.loaded = true
    },
    beforeDestroy() {
     //  this.storeWatcher()
    },
    methods: {
      onFilterVessels(item, queryText){
        return item.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
      },
      toggleIconBasedOnCaseType(currentCase){
        if(currentCase.InsuranceType === 'PI' && currentCase.DamageType === 'Crew'){
          return 'mdi-account-hard-hat'
        } if(currentCase.InsuranceType === 'PI' && currentCase.DamageType==='ECR'){
          return 'mdi-account-heart-outline'
        } else {
          return 'mdi-file-edit-outline'
        }
      },
      getToolTip(item){
          if(item.Subject){
            return item.Subject
          } else if(item.InsuranceType === 'PI' &&
            (item.DamageType === 'Crew' || item.DamageType==='ECR')){
              const crew = this.findCrew(item)
              return `${crew.InjuryIllnessType} - ${crew.Name} - ${crew.Age}`
          }
      },
      findCrew(claim){
        return this.$store.state.crew.find(f => f.ClaimId === claim.id)
      },
      fetchTotal(pagination) {
        this.total = pagination.itemsLength
      },
      clearFilters() {
        // Scrub url
        this.vessel = null
        this.reference = null
        this.category = null
        this.statusFilterValue = null
        this.type = null
        this.dateRangeText = null
        this.name = null
      },
      scrollIntoView() {
        this.$nextTick(() =>{
          const element = this.$el.getElementsByClassName("hi-lite")[0]
          const container = this.$el.getElementsByClassName('scroller')[0]
          if(!element || !container) {
            return
          }
          // Only scroll if needed: https://stackoverflow.com/a/45851497
          if (element.offsetTop < container.scrollTop) {
            container.scrollTo({top: element.offsetTop, behavior: 'smooth'});
          } else {
            const offsetBottom = element.offsetTop + element.offsetHeight;
            const scrollBottom = container.scrollTop + container.offsetHeight;
            if (offsetBottom > scrollBottom) {
              container.scrollTo({top: element.offsetTop, behavior: 'smooth'});
            }
          }
        })
      },
      shouldHilite(item) {
        return this.selected && this.selected.length>0 && this.selected[0].id === item.id
      },
      short(item) {
        return `${item.ClaimCategory} - ${item.Status}`
      },
      summary(item) {
          let limit = (this.openClaim) ? 160 : 315
          let raw = (item.Subject) ? item.Subject : item.Description
          if(!item.Subject && item.InsuranceType === 'PI' && (item.DamageType === 'Crew' || item.DamageType === 'ECR' )){
            const crew = this.findCrew(item)
            return `${crew.Rank} - ${crew.Name}`
          }
          return (raw && raw.length>limit) ? raw.substr(0,limit)+"..." : raw
      },
      row_classes(item) {
        if (this.selected.length>0 && item && this.selected[0].id == item.id) {
          return "hi-lite pointer";
        } else {
          return 'pointer'
        }
      },
      sortAplhabetically(immutable, field) {
          let arrayOfObjects = [... immutable]
          return arrayOfObjects.sort((a,b) => {
            const aa = a[`${field}`].toLowerCase().replace(/^\s+|\s+$/g, '').charAt(0)
            const bb = b[`${field}`].toLowerCase().replace(/^\s+|\s+$/g, '').charAt(0)
            return aa > bb ? 1 : ( (aa < bb) ? -1 : 0 )
          })
      },
      removeNulls(array, field) {
        return array ? array.filter(item => {
          const val = item[`${field}`]
          return val != null && typeof val === 'string' && val.replace(/^\s+|\s+$/g, '') !== ''
        }) : []
      },
      setOneYearOld() {
        let oneYearAgo = new Date()
        const today = new Date()
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
        const arr =[[oneYearAgo.getFullYear(), new String(oneYearAgo.getMonth() + 1).padStart(2, '0'), new String(oneYearAgo.getDate()).padStart(2, '0')].join('-'),
                      [today.getFullYear(), new String(today.getMonth() + 1).padStart(2, '0'), new String(today.getDate()).padStart(2, '0')].join('-')]
        this.dates = arr
      },
      setOneMonthOld() {
        let oneYearAgo = new Date()
        const today = new Date()
        oneYearAgo.setMonth(oneYearAgo.getMonth() - 1)
        const arr = [[oneYearAgo.getFullYear(), new String(oneYearAgo.getMonth() + 1).padStart(2, '0'), new String(oneYearAgo.getDate()).padStart(2, '0')].join('-'),
                      [today.getFullYear(), new String(today.getMonth() + 1).padStart(2, '0'), new String(today.getDate()).padStart(2, '0')].join('-')]
        this.dates = arr
      },
      clearRange() {
        this.cleanDateRange();
        this.registerFilter('range', this.getDatesArrForFilter)
      },
      cleanDateRange() {
        this.dates = []
      },
      checkandClose() {
        if(this.dates.length===2) { this.menu === false}
      },
      getCols(field) {
        const fieldMap = {
          'Vessel': 2,
          'CaseReference': 2,
          'Type': 2,
          'Status': 12,
          'Category': 2,
          'Range': 2,
          'Name': 2
        }
        const fieldMapOpen = {
          'Vessel': 3,
          'CaseReference': 3,
          'Status': 6,
          'Category': 3,
          'Type': 3,
          'Range': 3,
          'Name': 3
        }
          if(!this.$store.state.openClaim) {
            return fieldMap[field]
          }else {
            return fieldMapOpen[field]
          }
      },
      registerFilter(name, item) {
        this.$router.push({ query: Object.assign({}, this.$route.query, { [name]: item }) });
      },
      rowClick: function (item) {
        /* this.$store.dispatch('showLoader').then(() => { */
          this.loaded = false
          if(this.$route.params.id != item.id){
            this.$router.push({ params: { id: item.id }, query: this.$route.query});
          }
            if(this.$store.state.openClaim){
              // this.$store.dispatch('unloadClaim').then( () => {
                return this.$store.dispatch('openClaim', {claim: item}).then( () => this.loaded = true )
                //})
            } else {
              this.$store.dispatch('openClaim', {claim: item}).then( () => this.loaded = true )
            }
          
        /* }) */
      },
      save() {
        // fix date
        this.claim.IncidentDate = new Date(this.calim.IncidentDate)
        this.$store.dispatch('saveClaim', { claim: this.claim}).then(() =>{
          this.$store.dispatch('unloadClaim').then(() => {
            this.$store.dispatch('getAudits')
          })
        })
      },
       /**
       * Filter for dessert names column.
       * @param value Value to be tested.
       * @returns {boolean}
       */
      rangeFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (this.dates.length!=2) {
          return true;
        }
        // Check if the current loop value (The dessert name)
        // partially contains the searched word.
        return value >= this.dates[0] && value <= this.dates[1]
      },
       /**
       * Filter for dessert names column.
       * @param value Value to be tested.
       * @returns {boolean}
       */
      nameFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.vessel || !value || typeof this.vessel != 'string') {
          return true;
        }
        // Check if the current loop value (The dessert name)
        // partially contains the searched word.
        return value && this.vessel.toLowerCase().startsWith(value.toLowerCase())
      },
       /**
       * Filter for dessert names column.
       * @param value Value to be tested.
       * @returns {boolean}
       */
      refFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.reference) {
          return true;
        }
        return value && value.toLowerCase().includes(this.reference.toLowerCase())
      },
       /**
       * Filter for dessert names column.
       * @param value Value to be tested.
       * @returns {boolean}
       */
      categoryFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.category) {
          return true;
        }
        return value && value.toLowerCase().includes(this.category.toLowerCase());
      },
      /**
       * Filter for dessert names column.
       * @param value Value to be tested.
       * @returns {boolean}
       */
      typeFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.type) {
          return true;
        }
        return value && value.toLowerCase().includes(this.type.toLowerCase());
      },
      /**
       * Filter for calories column.
       * @param value Value to be tested.
       * @returns {boolean}
       */
      statusFilter(value) {
        // If this filter has no value we just skip the entire filter.
        if (!this.statusFilterValue) {
          return true;
        }
        // Check if the current loop value (The calories value)
        // equals to the selected value at the <v-select>.
        return value && value.toLowerCase() === this.statusFilterValue.toLocaleLowerCase();
      },
      getName(item) {
        if (!this.$store.state.vessels[item.Vessel_ID]) return ''
        return this.$store.state.vessels[item.Vessel_ID]['VESSELNAME']
      },
      getSlotName(item) {
        const structure = this.$store.state.structure.find(s => s.Slot_ID === item.Slot_ID)
        return (structure) ? structure.Slot : ""
      },
      getPortIfAny(item) {
        const port =  (item.Port_ID) ? this.$store.state.ports.find( p=> p.ID == item.Port_ID ) : null
        return (port) ? port.Name : ''
      },
    }
  }
</script>

<style lang="scss">
.scroller {
  margin-top: 10px;
  overflow-y: auto;
  min-height: 0px;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

    .truncate {
      max-width: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
.v-data-table-header {
  background-color: #8FB3C2;
}
.hi-lite {
  background-color: #254b65 !important;
  color: white;
}
.pointer {
  cursor: pointer;
}
.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: black;
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}

.v-btn-toggle > .v-btn.v-btn--active {
  .v-icon {
    color: darkslategrey  !important;
  }
  .v-btn__content {
    color:  darkslategrey !important;
    font-weight: bold;
  }
}

</style>