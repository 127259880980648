<template>
<v-dialog
    max-width="600"
    v-model="showDialog"
    @click:outside="close"
>
    <v-card class="d-flex pa-4">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-textarea outlined v-model="message" autofocus label="Log Message" ></v-textarea>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="6">
                    <v-btn text color="secondary" @click="close()">Cancel</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn :disabled="!checkRequired" color="primary" @click="save(message)">Store Message</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</v-dialog>
</template>
<script>

  export default {
    name: 'NewLogForm',
    props: ['dialog', 'save', 'close'],
    components: {

    },
    data: () => ({
        menu: false,
        message: '',
    }),
    computed: {
        checkRequired() {
            return this.message != ''
        },
        showDialog: {
            get () {
                return this.dialog
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    mounted: function(){
    }
}
</script>