<template>
  <div id="app">
    <v-app>
        <v-container fluid class="fill-height pa-0">
          <v-row class="d-flex align-start flex-column fill-height">
            <v-col class="d-flex justify-center shrink logo">
                <img src="/logo.png">
            </v-col>
            <v-col class="d-flex flex-row justify-center shrink nav">
                  <v-progress-linear
                    v-if="!loaded"
                    indeterminate
                    color="cyan"
                  ></v-progress-linear>
                <v-fade-transition>
                  <v-btn text tile outlined ripple dark to="/claims" :class="{ 'active-button' : isSubPage}" active-class="active-button" v-if="loaded" class="ma-3 elevation-3">
                    Cases
                  </v-btn>
                </v-fade-transition>
                <v-fade-transition>
                  <v-btn text tile outlined ripple class="ma-3 elevation-2 new-button" v-if="loaded" @click="newFormDialog = true">
                    <v-icon>mdi-plus</v-icon>New case
                  </v-btn>
                </v-fade-transition>
                <v-fade-transition>
                  <v-btn text tile ripple outlined dark active-class="active-button" to="/help" v-if="loaded" class="ma-3 elevation-3">
                    Help
                  </v-btn>
                </v-fade-transition>
                <v-fade-transition>
                  <v-btn text tile ripple outlined dark active-class="active-button" to="/about" v-if="loaded" class="ma-3 elevation-3">
                    About
                  </v-btn>
                </v-fade-transition>
                <v-fade-transition>
                  <v-btn text tile ripple outlined dark active-class="active-button" to="/admin" v-if="loaded" class="ma-3 elevation-3">
                    Admin
                  </v-btn>
                </v-fade-transition>
                <v-fade-transition>
                  <v-btn text tile ripple outlined dark active-class="active-button" to="/logout" v-if="loaded" class="ma-3 elevation-3">
                    Log out {{shortName}}
                  </v-btn>
                </v-fade-transition>
                <span style="color:lightgray; font-size:10px;">{{ envName }} v{{ guiVersion }}/{{ apiVersion }}</span>
            </v-col>
            <v-col class="d-flex grow ma-0 pt-0 pb-0 pl-10 pr-10">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
    </v-app>
    <new-form v-if="newFormDialog" :dialog="newFormDialog" :save="saveNew" :close="closeNewDialog" />
    <v-bottom-sheet
      v-model="showError"
    >
      <v-sheet
        class="text-center pt-3"
        height="200px"
      >
          <v-alert
            class="mr-10 ml-10"
            outlined
            type="error"
            prominent
            border="left"
          >
            {{$store.state.error}}
          </v-alert>
        <div class="my-3">
          <v-btn
            class="mt-6"
            color="error"
            @click="$store.dispatch('hideError')"
          >
            close
          </v-btn>
          <v-btn
            class="mt-6 ml-3"
            color="error"
            href="Javascript:location.reload()"
          >
            reload page
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>


  </div>
</template>

<script>
import NewForm from './components/NewForm.vue'
export default {
  name: "App",
  data: () => ({
    newFormDialog: false,
  }),
  components: {
    NewForm
  },
  computed: {
      envName() {
          return this.$store.state.metadata[0]?.envName || ''
      },
      apiVersion() {
          return this.$store.state.metadata[0]?.APIv || ''
      },
      guiVersion() {
        return require('../package.json').version
      },
      shortName() {
        return this.$store.state.handler ?  this.$store.state.handler.split(' ').shift() : ''
      },
      showError() {
        return this.$store.state.error != ''
      },
      loaded() {
        return !this.$store.state.loading
      },
      isSubPage() {
        return this.$route.name == 'Editor'
      },
      name() {
        if (!this.loaded || !this.$route.params.id || !this.$store.state.vessels[this.$route.params.id]) return ''
        return this.$store.state.vessels[this.$route.params.id]['VESSELNAME']
      },
  },
  methods: {
    closeNewDialog() {
      this.newFormDialog = false
    },
    saveNew(item, crew) {
      this.$store.dispatch('createClaim', {newItem: item, newCrew: crew}).then(() => {
        this.$store.dispatch('getCrew').then(() => {
          this.$store.dispatch('getAudits').then(() => {
            this.$store.dispatch('unloadClaim')
          })
        })
      })
      this.newFormDialog=false
    },
    toTitleCase(phrase) {
      return phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  }
};
</script>


<style lang="scss">
.v-application--wrap {
    height: 100vh !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #254b65;
  background-color: rgb(215 215 215);
  font: 1em "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
}
.logo {
  background: rgb(143,179,194);
  background: linear-gradient(0deg, rgba(143,179,194,1) 0%, rgba(143,179,194,0.8) 100%);
  padding: 10px;
}
.nav {
  background-color: #254b65;
  background: linear-gradient(0deg, rgba(37,75,101,0.9) 0%, rgba(37,75,101,1) 100%);
  border-top: 1px solid black;
  border-bottom: 1px solid;
  a {
    text-decoration: none;
    font-weight: bold;
    .v-btn {
      background-color: whitesmoke;
      .v-btn__content {
        font: bold 1em "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
        text-align: center;
        color: hsla(0,0%,20%,1);
        text-shadow: hsla(0,0%,40%,.5) 0 -1px 0, hsla(0,0%,100%,.6) 0 2px 1px;
      }
    }
  }
  .new-button {
    color: whitesmoke !important;
    background-color: orange;
  }
  .active-button {
    color: #0161A8 !important;
    background-color: whitesmoke;
  }
}
.v-image {
  filter: drop-shadow(#1976d2 2px 2px 2px);
}
.drop {
  filter: drop-shadow(3px 3px 2px black);
}
.chosen_vessel {
  display: inline-block;
  color: whitesmoke;
  right: 0;
  margin-top: 20px;
  margin-right: 10px;
  position: absolute;
}
</style>
