<template>
<v-container fluid>
            <v-row class="w-100">
                <v-col cols="12">


 <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary">
                <v-icon large>mdi-shape-polygon-plus</v-icon>
                    Add new Vessel
                </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Add new Vessel</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-left">                  
                    <p>In this dialog we create a new Vessel. Please ensure name is correct. Only after creation, you will be able to the edit the values associated with the new Vessel back in the "normal" admin window.</p>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="newname"
                  ref="newNameInput"
                  label="New Vessel Name"
                  hint="This is the vessel name for the new meta data"
                  persistent-hint
                  @keyup="uppercase"
                  :rules="[...rules, checkName, checkNameProbably]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-icon></v-icon>
                  <v-alert
                    icon="mdi-exclamation"
                    prominent
                    text
                    type="info"
                    class="text-left"
                    >
                    A new vessel name will be in uppercase (conforming with legacy data) and checked for duplicates. Please observe that a miss spelling here could cause dual entries in the database (with deceptively similar names)
                  </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveIt"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



                </v-col>
            </v-row>
</v-container>
</template>
<script>
import { getISO8601ShortDateFromDate } from './Tools'

  export default {
    name: 'NewMetadataTool',
    props: ['save', 'data'],
    components: { },
    data: () => ({
        menu: false,
        isOkay: false,
        loaded: false,
        dialog: false,
        newname: '',
        rules: [
          v => !!v || 'Vessel name is required',          
          v => v === v.trim() || 'No leading or trailing spaces'
        ]      
    }),
    watch: {
        metadata: function(newVal) {
            this.created = {... newVal}
        }
    },
    computed: {  
      list() {
        return this.data ? this.data.map(m => m.Name) : []
      }         
    },
    methods: {
        getISO8601ShortDateFromDate,
        saveIt(){
            if (this.$refs['newNameInput'].hasError) {
              return
            }
            this.loaded=false
            this.save({ 'Name': this.newname}).then(() => {
                this.loaded=true
                this.dialog=false
            })
        },
        uppercase() {
          this.newname = this.newname.toUpperCase();
        },
        checkName(value) {          
          return this.list.includes(value.toUpperCase()) ? 'Vessel exists from before' : true
        },
        checkNameProbably(value) {
          return value.indexOf(' ') > -1 && this.list.find(m => m.indexOf(value) > -1) ? 'Vessel probably exists from before' : true
        }
    },
    mounted: function(){
        this.created = {... this.metadata}
        this.loaded = true
    }
}
</script>

<style scoped>
.calculated.v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
}
</style>