
<template>
  <v-container fluid>
    <upload :dialog.sync="uploadDialog" :multiple="true" @filesUploaded="processUpload($event)"/>
    <v-card elevation="2" class="ma-1">
      <v-data-table
        :items="list"
        :headers="headers"
        sort-by="niceCreatedDate"
        sort-desc
        item-key="id"
      >
      <template v-slot:[`item.link`]="{ item }">
        <v-btn text :loading="item.downloading" @click="download(item)">
          <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.uploaded`]="{ item }">
        {{getISO8601dateFromDate(item.metadata.modified)}}
      </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showloader"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Uploading...
        </v-card-title>

        <v-card-text>
          <v-progress-linear
            class="mt-6"
            v-if="showloader"
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Upload from '../components/Upload'
import { getISO8601dateFromDate } from './Tools'
export default {
  name: 'Attachments',
  data: () => ({
    loaded: false,
    uploadDialog: false,
    showloader: false,
  }),
  components: {
    Upload
  },
  computed: {
    list() {
      return this.$store.state.attachments.filter((f) =>
      f.metadata && f.metadata.id == this.selection.id).map(m =>
      ({...m, downloading: false,
              niceDate: getISO8601dateFromDate(m.metadata.modified),
              niceCreatedDate: getISO8601dateFromDate(m.metadata.created)}))
    },
    headers(){
      return [
              { text: 'Link', value: 'link', width: '70px', align: 'center', sortable: false},
              { text: 'Uploaded', value: 'niceCreatedDate', width: '170px'},
              { text: 'Modified', value: 'niceDate', width: '170px'},
              { text: 'Filename', value: 'filename'},
            ]
    },
    selection() {
      return this.$store.state.openClaim
    }
  },
  methods: {
    getISO8601dateFromDate,
    download (item) {
      item.downloading = true
      this.$store.dispatch('downloadFile', item.filename).then(data => {
        const ext = item.filename.split('.')[1]
        const linkSource = `data:application/${ext};base64,${data.data}`
        const byteString = atob(linkSource.split(',')[1])
        const mimeString = linkSource
          .split(',')[0]
          .split(':')[1]
          .split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        // write the ArrayBuffer to a blob, and you're done
        const fileBlob = new Blob([ab], { type: mimeString })

        /**
         * Internet Explorer stuff!
         */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(fileBlob, `${data.filename}`)
          return
        }
        //Use atob only to decode Base64 to binary and show some information about the PDF file (note that I skipped all checks)
        const url = window.URL.createObjectURL(fileBlob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${data.filename}`)
        document.body.appendChild(link)
        link.click()

        link.remove()
        item.downloading = false
      })
    },
    async processUpload(e) {
      //this.$store.dispatch('showLoader')
      this.showloader = true
      const promiseArr = []
      for (const file of e) {
        const data = await this.toBase64(file)
        const fileData = {base64: data.split(",").pop(),
          name: file.name,
          modified: file.lastModifiedDate,
          size: file.size,
          type: file.type,
          id: this.$store.state.openClaim.id
        }
        promiseArr.push(this.$store.dispatch('uploadFile', fileData))
      }
      Promise.all(promiseArr)
        .then(() => {
          this.showloader = false
        })
        .catch((e) => {
          this.$store.dispatch('showError', 'Error while uploading files \n' + e)
          this.showloader = false
        })
     },

    toBase64(file) {
      return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
      })
    },
    fileSizePrinter(size){
      return (size/(1024*1024)).toFixed(2)+" Mb"
    },
    cancel() {
      this.$store.dispatch('unloadClaim')
    }

  }
}
</script>

<style>
.v-slide-group__wrapper
{
  background-color: #c8d6df;
  background-color: #c8d6df;
}
  form{
    display: block;
    height: 400px;
    width: 400px;
    background: #ccc;
    margin: auto;
    margin-top: 40px;
    text-align: center;
    line-height: 400px;
      border-radius: 4px;
  }
</style>