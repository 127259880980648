<template>
  <div>
    <v-btn color="primary" text outlined @click="parseDateDialog = !parseDateDialog"><v-icon >mdi-keyboard-variant</v-icon></v-btn>
    <v-dialog
      v-model="parseDateDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Enter date and hit Enter to import</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="rawDate"
                  outlined
                  autofocus
                  dense
                  hide-details="auto"
                  label="Paste or type date and see if we can use it!"
                  required
                  @keyup.enter="save(rawDate)"
                ></v-text-field>

              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                 <v-alert
                    v-if="tryParsedDate"
                    dense
                    text
                    border="left"
                    colored-border
                    type="success"
                    elevation="2"
                  >
                    You can import this string as the Incident date: {{asReadableDate}}
                  </v-alert>
                 <v-alert
                    v-if="!tryParsedDate"
                    dense
                    text
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                  >
                    Current string is not a date
                  </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="parseDateDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            success="tryParsedDate"
            error="!tryParsedDate"
            :disabled="!tryParsedDate"
            @click="save(rawDate)"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DateImporter',
    props: ['parse'],
    components: { },
    data: () => ({
      rawDate: null,
      parseDateDialog: false
    }),
    methods: {
      save() {
        if(this.tryParsedDate) {
          this.parse(this.rawDate)
          this.parseDateDialog = false
        }
      }
    },
    computed: {
      tryParsedDate() {
        return Date.parse(this.rawDate)
      },
      asReadableDate() {
        return (this.tryParsedDate) ? new Date(Date.parse(this.rawDate)).toLocaleDateString().slice(0, 10) : ''
      }
    },
  }
</script>