<template>
  <v-container fluid class="fill-height">
        <v-card elevation="2" class="grow">
            <v-card-title>
                <v-card
                    elevation="2"
                    color="#59656F"
                    dark
                    dense
                    class="ma-0 grow"
                    tile
                >
                <v-card-text>
                        <v-row justify="space-between">
                            <v-col cols="2" v-if="claim.id">
                                <div><span class="fatwhite">ID</span> {{claim.id}}</div>
                            </v-col>
                            <v-col cols="5" v-if="claim.ClaimCreated">
                                <div><span class="fatwhite">CREATED</span> {{new Date(claim.ClaimCreated).toLocaleDateString()}}</div>
                            </v-col>
                            <v-col cols="5" v-if="claim.CaseReference">
                                <div><span class="fatwhite">REF</span>{{claim.CaseReference}}</div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-title>
            <v-card-text class="fill-height">
                <v-row>
                    <v-col cols="4" lg="2">
                        <v-select
                            v-model="type"
                            outlined
                            :items="getTypes"
                            label="Type"
                            dense
                            hide-details="auto"
                            @change="damage=null"
                        />
                    </v-col>
                    <v-col cols="4" lg="3">
                        <v-select
                            v-model="damage"
                            v-if="getDamages!=null"
                            outlined
                            :items="getDamages"
                            label="Damage"
                            dense
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="4" lg="2">
                        <v-select
                            v-model="subs"
                            v-if="getSubs!=null && getSubs.length>0"
                            outlined
                            :items="getSubs"
                            label="Sub category"
                            dense
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" lg="5" class="d-flex justify-end">
                        <v-btn
                            class="top mr-3"
                            color="primary"

                            @click="localsave({'type': type, 'damage': damage, 'subs': subs})"
                            :disabled="!isDirty || missingDamage">
                            <v-icon>mdi-save</v-icon> Recategorize
                        </v-btn>
                        <v-btn class="top" outlined @click="cancel()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

  export default {
    name: 'Topbar',
    props: ['save', 'cancel'],
    components: {

    },
    data: () => ({
        type: null,
        damage: null,
        subs: null,
        dirty: false,
        hierarchy: {
            'HM': {
                'Heavy Weather': null,
                'Fire/Explosion': null,
                'Collision, Contact, Grounding': ['Fenders', 'Floating object', 'Vessel', 'Propeller/rudder'],
                //'Property damage': ['Crane', 'Engine', 'Fire', 'Hull', 'Propeller/rudder', 'Other'],
                'Machinery': ['Main engine', 'Aux engine', 'Other'],
                'Vessel lost': null,
                'Other': null,
            },
            'PI': {
                'Cargo': null,
                'Collision': ['Fenders', 'Floating object', 'Vessel'],
                'Crew': [ 'Injury', 'Illness', 'Other'],
                'ECR': [ 'Injury', 'Illness', 'Other'],
                'Fine': null,
                'Pollution': null,
                'Property damage': ['Crane', 'Engine', 'Fire', 'Hull', 'Propeller/rudder', 'Other'],
                'Removal of wreck': null,
                'Third party damage':null,
                'Smuggling': null
            },
            'Strike': null,
            'Legal': null,
            'Charterers Liability': {
                'Cargo': null,
                'Crew': null,
                'Damage to Vessel': null,
                'Demurage': null,
                'Legal dispute': null,
                'Unsafe port': null
            }
        },
        personDamageHierarky: {
            'Crew': {   Injury: ['Amputation', 'Burn', 'Constitution', 'Crush', 'Cut', 'Fracture', 'General pain', 'Laceration', 'Other', 'Strain/Sprain'],
                        Illness: ['Infectious disease', 'Mental disease', 'Non-infectous disease', 'Physical disease'],
                        Other: ['Missing at sea', 'Stowaways', 'Desertion']
                    },
            'ECR': {   Injury: ['Amputation', 'Burn', 'Constitution', 'Crush', 'Cut', 'Fracture', 'General pain', 'Laceration', 'Other', 'Strain/Sprain'],
                        Illness: ['Infectious disease', 'Mental disease', 'Non-infectous disease', 'Physical disease'],
                        Other: ['Missing at sea', 'Stowaways', 'Desertion']
                    },
        }

    }),
    watch: {
        data: {
            handler(newValue) {
                this.type = newValue.InsuranceType
                this.damage = newValue.DamageType
                this.subs = newValue.DamageTypeSubCategory
            }
        },
        claim: {
        immediate: true,
        handler(newValue) {
            this.type = newValue.InsuranceType
            this.damage = newValue.DamageType
            this.subs = newValue.DamageTypeSubCategory
        }
      },
    },
    computed: {
      getTypes() {
        let arr = Object.keys(this.hierarchy)
        return arr
      },
      getDamages() {
        if(!this.hierarchy[this.type]) return null
        let arr = Object.keys(this.hierarchy[this.type])
        return arr
      },
      getSubs() {
        if(!this.damage || !this.hierarchy[this.type] || !this.hierarchy[this.type][this.damage]) return null
        let arr = this.hierarchy[this.type][this.damage]
        return arr
      },
      isDirty() {
        return this.type!=this.claim.InsuranceType || this.damage!=this.claim.DamageType || this.subs!=this.claim.DamageTypeSubCategory
      },
      missingDamage() {
        return this.getDamages!=null && this.damage===null
      },
      claim() {
        return this.$store.state.openClaim
      }
    },
    mounted: function(){
        this.type = this.claim.InsuranceType
        this.damage = this.claim.DamageType
        this.subs = this.claim.DamageTypeSubCategory
    },
    methods: {
        localsave() {
            const sendable = {
                type: null,
                damage: null,
                subs: null,
            }
            sendable.type = this.type
            sendable.damage = (this.getDamages!=null) ? this.damage : null
            sendable.subs = (this.getSubs!=null && this.getSubs.length>0) ? this.subs : null
            this.save(sendable)
        },
    }
}
</script>

<style lang="css">
.fatwhite {
    margin-right: 10px;
    font-weight: bold;
    color: white;
}
</style>