<template>
  <v-container fluid class="fill-height align-start">
    <v-row v-if="$store.state.loading" class="fill-height justify-center align-center">
      <v-progress-circular
        :size="170"
        :width="7"
        color="#2D526B"
        indeterminate
      ></v-progress-circular>
    </v-row>

    <v-row v-if="!$store.state.loading" class="fill-height">
      <v-col cols="12">
        <v-row class="d-flex flex-column fill-height">
          <v-col class="white--text grow overflow-auto light scroller">
            <v-row>
              <v-col cols="4">
                <v-row class="d-flex">
                  <v-col cols="12" class="align-start" >
                    <v-card class="">
                      <v-card-text>
                                              <v-text-field
                          v-model="metaName"
                          clearable
                          outlined
                          dense
                          @keyup="uppercase"
                          label="Filter"
                        />
                        <div>
                          <v-data-table
                            :loading="$store.state.loading"
                            :headers="headers"
                            :items="getMetadata"
                            item-key="id"
                            multi-sort
                            hide-default-footer
                            disable-pagination
                            class="row-pointer"
                            single-select
                            dense
                          >
                            <template v-slot:item="{ item }">
                                <tr @click="rowClick(item)" :key="item.name" :class="{'hi-lite': shouldHilite(item), 'pointer':true}">
                                  <td class="text-left" style="width: 150px;">
                                    {{item.Code}}
                                  </td>
                                  <td class="text-left">{{ item.Name }} </td>
                                  <td class="text-left">{{ item.Type }} </td>
                                  <td class="text-left">{{ item.BuiltYear }} </td>
                                </tr>
                              </template>
                          </v-data-table>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                </v-row>
              </v-col>
              <v-col cols="8">
                <v-card class="mb-5">
                  <v-card-text>
                    <v-row>
                      <v-col cols=6>
                        <new-metadata-tool
                          :save="saveNewCallback"
                          :data="getSelected"
                        />
                      </v-col>
                      <v-col cols=6>
                        <v-container fluid>
                          <v-row class="w-100">
                            <v-col cols="12">


                              <v-dialog
                                v-model="dialog"
                                persistent
                                max-width="80%"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      color="primary">
                                          <v-icon large>mdi-database-import</v-icon>
                                              Import from CSV
                                          </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">Import CSV</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="12"
                                          md="12"
                                        >
                                          <v-textarea
                                            style="font-family:monospace; font-size: 8pt;"
                                            v-model="importcsv"
                                            label="Paste CSV code here"
                                            hint="Paste exported CSV code in this field"
                                            persistent-hint
                                            required
                                          ></v-textarea>
                                        </v-col>

                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="cancelDialog()"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="parseCSVToDB()"
                                      :loading="loading"
                                    >
                                      Import
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>



                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <metadata-form
                  v-if="metaId"
                  :metadata="metaId"
                  :save="saveCallback"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MetadataForm from '../components/MetaDataForm.vue'
import NewMetadataTool from '../components/NewMetadataTool.vue'
import { parse } from 'csv-parse';
  export default {
    name: 'AdminView',
    props: ['data', 'id'],
    data: () => ({
      loaded: false,
      state: true,
      metaId: [],
      metaName: '',
      dialog: false,
      importcsv: null,
      loading: false,
    }),
    components: {
      MetadataForm, NewMetadataTool
    },
    mounted: function(){
      this.loaded = false;
      this.$store.dispatch('showLoader').then(() =>
        this.init().then(() =>
          this.$store.dispatch('hideLoader').then(
            () => this.loaded = true
          )
        )
      )
    },
    computed: {
        showLoader () {
            return this.loaded && this.$store.state.loading
        },
        getMetadata() {
          let data = this.$store.state.metadata
          const filter = this.metaName
          data.sort((a,b)=> a.Name.localeCompare(b.Name))

          return  this.$store.state.metadata ? data.filter(t => t.Name.startsWith(filter)) : []
        },
        getSelectedId() {
          return this.metaId ? this.metaId.id : null
        },
        getSelected() {
          if(this.getSelectedId == null) return
          return this.getMetadata.find((f) => f.id === this.getSelectedId) ? this.getMetadata.find((f) => f.id === this.getSelectedId) : []                  
        },
        headers() {
          return [
              { text: 'Code', value: 'Code', align: 'start', width: '80px', groupable: false},
              { text: 'Name', value: 'Name', width: '200px', groupable: false},
              { text: 'Type', value: 'Type', width: '110px', groupable: false},
              { text: 'Built', value: 'BuiltYear', width: '80px', groupable: false},
            ]
        },

    },
    watch: {
      metaName() {
        if(!this.metaName) {
          this.metaName = ''
        }
      },
    },
    methods: {
      rowClick: function (item) {        
        this.metaId = item
      },
      uppercase() {
        this.metaName = this.metaName.toUpperCase();
      },
      shouldHilite(item) {
        return item && this.metaId ? item.id === this.metaId.id : false
      },
      scrollIntoView() {
        this.$nextTick(() =>{
          const element = this.$el.getElementsByClassName("hi-lite")[0]
          const container = this.$el.getElementsByClassName('scroller')[0]
          if(!element || !container) {
            return
          }
          // Only scroll if needed: https://stackoverflow.com/a/45851497
          if (element.offsetTop < container.scrollTop) {
            container.scrollTo({top: element.offsetTop, behavior: 'smooth'});
          } else {
            const offsetBottom = element.offsetTop + element.offsetHeight;
            const scrollBottom = container.scrollTop + container.offsetHeight;
            if (offsetBottom > scrollBottom) {
              container.scrollTo({top: element.offsetTop, behavior: 'smooth'});
            }
          }
        })
      },
      init() {
        return this.$store.dispatch('getMetadata')
      },
      saveCallback(item) {
        this.loaded = false
        return this.$store.dispatch('saveMetadata', { metadata: item})
          .then(() => {
            this.loaded = true
          })
          .catch((error) => {
            this.loaded = true
            this.$store.dispatch('showError', 'Error while saving to VesselClaims, please reload page and retry changes \n', error)
          })
      },
      saveNewCallback(item){
            this.loaded=false
            return this.$store.dispatch('createMetadata', { metadata: item})
          .then(async (data) => {
            this.loaded = true
            await this.$store.dispatch('getMetadata')
            this.metaId = data.data[0].id
          })
          .catch((error) => {
            this.loaded = true
            this.$store.dispatch('showError', 'Error while crteating new record in VesselClaims, please reload page and retry changes \n', error)
          })
        },
      async parseCSVToDB() {
        this.loading = true
        parse(this.importcsv, async (err, records) => {
          if(err){
            this.$store.dispatch('showError', 'Unknown error while parsing CSV \n', err)
            return
          }
          const header = records.shift()
          for(const separated of records) {
            let newRow = {}
            for(let i in separated) {
              newRow[header[i]] = separated[i].trim()==='NULL' ? null : separated[i].trim()
            }
            const needle = this.getMetadata.find((f) => f.Name === newRow.Name)
            newRow.id = needle.id
            const result = {...needle, ...newRow}
            await this.saveCallback(result)
          }
          this.loading = false
          this.dialog = false
        })
      },
      cancelDialog(){
        this.importcsv = null
        this.dialog = false
      }
    },
  }
</script>

<style scoped>
.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: black;
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>