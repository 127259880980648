<template>
    <div class="scroller">
          <v-fade-transition>
    <v-card
      elevation="2"
      class="fill-height globallog "
    >
        <v-card-title>Recent activity</v-card-title>
        <v-card-text class="text-left">

          <v-row>
            <v-col cols="12">
              <v-card
                class="mx-auto"
                tile
              >
                <v-alert
                  :value="showAlert"
                  type="info"
                  outlined
                  light
                  border="left"
                  transition="slide-y-transition"
                >
                      The recent activity is grouped by claim, so only the most <u>recent</u>
                      activity <u>per claim</u> is shown in this list.
                </v-alert>
              </v-card>
            </v-col>
           </v-row>
                <v-card v-for="item of list" :key="item.id" outlined elevation="2" class="mt-4 mr-1 rowpointer">
                    <v-list-item three-line>
                      <v-list-item-content @click="open(item.claim)">
                        <v-list-item-title>
                          <v-row>
                            <v-col>
                              <b>{{item.CreatedBy}}</b> {{getISO8601dateFromDate(item.CreatedDate)}}
                            </v-col>
                            <v-col>
                              <v-row class="pa-1" justify="end">
                                  <v-icon class="ma-2">{{mapIconToCategory(item.Category)}}</v-icon>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{item.claim && item.claim.CaseReference}}
                          {{item.claim && item.claim.CaseReference ? ' - ': ''}}
                          {{item.claim && item.claim.Vessel}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>{{item.Message.substring(0,40)}}</b>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                </v-card>
          <new-log-form v-if="newFormDialog" :dialog="newFormDialog" :save="saveNew" :close="closeNewDialog"/>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
    </v-card>
          </v-fade-transition>
    </div>
</template>

<script>
import { getISO8601dateFromDate } from './Tools'
  export default {
    name: 'GlobalLog',
    props: ['claimid'],
    components: {
    },
    created(){
      setTimeout(()=>{
          this.showAlert=false
        },10000)
    },
    data: () => ({
      newFormDialog: false,
      menu: false,
      item: {},
      showAlert: true,
    }),
    computed: {
      list() {
        if (!this.$store || !this.$store.state.audits || !this.$store.state.claims) {
          return null
        }
        const loglist = []
        const audits = this.$store.state.audits.filter(f => f.Message)
        for(const entry of audits) {
          const found = loglist.find(f => f.CaseId == entry.CaseId)
          if(!found)
          {
            const claim = this.$store.state.claims.find(f => f.id === entry.CaseId)
            if(claim){
              const merged = { ...entry, 'claim': claim}
              loglist.push(merged)
            }
          }
        }
        return loglist
      },
    },
    mounted: function(){
      if(!this.list) {
        return ""
      }
    },
    methods: {
      getISO8601dateFromDate,
      mapIconToCategory(cat) {
        const categoryMap = {
          'UserMessage': 'mdi-chat',
          'CreatedClaim': 'mdi-creation',
          'ModifiedClaim': 'mdi-square-edit-outline',
          'AddedAttachment': 'mdi-file-outline'
        }
        return categoryMap[cat]
      },
      mappedTitle(cat){
        const map = {
          'UserMessage': 'User Message',
          'CreatedClaim': 'Creation',
          'ModifiedClaim': 'Modification',
          'AddedAttachment': 'Uploaded file'
        }
        return map[cat]
      },
      saveNew(message) {
        const newItem = {
          CaseId: this.$store.state.openClaim.id,
          Message: message,
          Category: 'UserMessage',
        }
        this.$store.dispatch('createLog', {newItem: newItem})
        this.newFormDialog=false
      },
      closeNewDialog() {
        this.newFormDialog = false
      },
      cancel() {
        this.$store.dispatch('unloadClaim')
      },
      open(claim) {
        this.$store.dispatch('openClaim', {claim: claim})
        this.$router.push({ params: { id: claim.id }, query: this.$route.query});
      }
    }
  }
</script>

<style lang="css" scoped>
.globallog {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    background-color: #8FB3C2;
}
.rowpointer:hover {
  cursor: pointer;
  background-color: #254b65 !important;
  color: white;
}

.scroller {
  margin-top: 10px;
  overflow-y: auto;
  min-height: 0px;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.pointer {
  cursor: pointer;
}
.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: black;
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>