import { render, staticRenderFns } from "./GlobalLog.vue?vue&type=template&id=fc84a7c4&scoped=true"
import script from "./GlobalLog.vue?vue&type=script&lang=js"
export * from "./GlobalLog.vue?vue&type=script&lang=js"
import style0 from "./GlobalLog.vue?vue&type=style&index=0&id=fc84a7c4&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc84a7c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCardActions,VCardText,VCardTitle,VCol,VFadeTransition,VIcon,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VRow})
